var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('a-row',[_c('a-col',{attrs:{"span":7}},[_c('div',{staticClass:"header left"},[_c('DataDictFinder',{attrs:{"dictType":"allocateType","dictValue":_vm.item.type}}),_c('DataDictFinder',{attrs:{"dictType":"majorTypes","dictValue":_vm.item.type}}),_c('span',[_vm._v(" 专业可分配数值（")]),_c('Money',{attrs:{"money":_vm.item.webAmount}}),_c('span',[_vm._v("万元）")])],1),(typeof _vm.item.webJs3ConstAmount === 'number')?_c('div',{staticClass:"cost",staticStyle:{"margin-bottom":"8px","display":"flex"}},[_c('DataDictFinder',{attrs:{"dictType":"majorTypes","dictValue":_vm.item.type}}),_c('span',[_vm._v("专业分包：")]),_c('Money',{attrs:{"money":_vm.item.webJs3ConstAmount}}),_c('span',[_vm._v("万元")])],1):_vm._e(),_c('div',{staticClass:"ratio-title"},[_c('span',{staticClass:"title"},[_c('DataDictFinder',{attrs:{"dictType":"allocateType","dictValue":_vm.item.type}}),_c('DataDictFinder',{attrs:{"dictType":"majorTypes","dictValue":_vm.item.type}})],1),_c('span',{staticStyle:{"background-color":"#fff","color":"#222","padding":"0 12px"}},[_vm._v(" "+_vm._s(_vm.item.allocateQuota)+"% ")])])]),(_vm.item.staffList.length > 0)?_c('a-col',{attrs:{"span":17}},[_c('div',{staticClass:"header between"},[_c('div',{staticClass:"left"},[_c('div',[_vm._v("参与分配人员")]),(
              _vm.item.staffList[0].role &&
              _vm.item.staffList.filter((obj) => obj.role === 'approval')
                .length === 0 &&
              _vm.phase.configurable
            )?_c('a-tooltip',{attrs:{"title":"增加审定"}},[_c('a-icon',{staticStyle:{"color":"#666","cursor":"pointer","margin-left":"4px"},attrs:{"type":"plus-circle"},on:{"click":function($event){return _vm.addRole('approval')}}})],1):_vm._e()],1),(_vm.item.collapsed)?_c('div',{staticStyle:{"color":"#999","font-size":"12px","cursor":"pointer"},on:{"click":function($event){_vm.item.collapsed = !_vm.item.collapsed;
            _vm.$forceUpdate();}}},[_c('span',{staticStyle:{"padding-right":"4px"}},[_vm._v("展开")]),_c('a-icon',{attrs:{"type":"down"}})],1):_c('div',{staticStyle:{"color":"#999","font-size":"12px","cursor":"pointer"},on:{"click":function($event){_vm.item.collapsed = !_vm.item.collapsed;
            _vm.$forceUpdate();}}},[_c('span',{staticStyle:{"padding-right":"4px"}},[_vm._v("折叠")]),_c('a-icon',{attrs:{"type":"up"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.item.collapsed),expression:"!item.collapsed"}]},[(_vm.item.staffList[0].role)?_c('div',_vm._l((_vm.roleList),function(role,index){return _c('div',{key:index},[_c('div',{staticClass:"left",staticStyle:{"margin-bottom":"8px","font-size":"14px"}},[_c('DataDictFinder',{attrs:{"dictType":"prjAllocateRole","dictValue":role.role}}),_c('span',[_vm._v(" （"+_vm._s(role.roleRatio)+"% "+_vm._s(" / ")+" "),_c('Money',{attrs:{"money":_vm.surplus * (role.roleRatio / 100)}}),_vm._v(" 万元 ） ")],1),(
                  role.role === 'approval' &&
                  _vm.roleFilter(role.role, _vm.item.staffList).length > 0 &&
                  _vm.phase.configurable
                )?_c('a-icon',{staticStyle:{"color":"#666","cursor":"pointer"},attrs:{"type":"delete"},on:{"click":function($event){return _vm.removeRole(role.role)}}}):_vm._e()],1),_c('div',{staticStyle:{"border":"1px solid #e0e0e0","background":"#f9f9f9","padding":"12px","margin-bottom":"8px"}},[(_vm.roleFilter(role.role, _vm.item.staffList).length > 0)?_c('div',_vm._l((_vm.roleFilter(
                    role.role,
                    _vm.item.staffList
                  )),function(element,idx){return _c('StaffItem',{key:idx,attrs:{"money":_vm.surplus * (role.roleRatio / 100),"phase":_vm.phase,"phaseIndex":_vm.phaseIndex,"phaseItem":_vm.item,"phaseItemIndex":index,"majorGroup":_vm.majorGroup,"role":role.role,"staffItem":element,"index":idx}})}),1):_vm._e()])])}),0):_c('div',_vm._l((_vm.item.staffList),function(element,idx){return _c('StaffItem',{key:idx,attrs:{"money":_vm.surplus,"phase":_vm.phase,"phaseIndex":_vm.phaseIndex,"phaseItem":_vm.item,"phaseItemIndex":_vm.index,"majorGroup":_vm.majorGroup,"staffItem":element,"index":idx}})}),1)])]):_vm._e()],1),_c('div',{staticClass:"file-title"},[_vm._v("附件")]),_c('div',{staticClass:"link-list"},[_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((Array.isArray(_vm.item.attachmentsList)
          ? _vm.item.attachmentsList
          : []),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id,"disabled":!_vm.phase.configurable}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0),(_vm.phase.configurable)?_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }