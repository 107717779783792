<template>
  <a-tabs
    style="width: 100%"
    tabPosition="left"
    :defaultActiveKey="defaultActiveKey"
  >
    <a-tab-pane v-for="item in configurableList" :key="item.id">
      <div slot="tab">
        <div>
          <span>{{ item.phaseName }}({{ item.phasePercent }}%)</span>
        </div>
      </div>

      <Phase :index="getIndex(item)" />
    </a-tab-pane>

    <a-tab-pane
      v-for="item in notConfigurableList"
      :key="item.id"
      :tab="`${item.phaseName}(${item.phasePercent}%)`"
      :disabled="true"
    >
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import Phase from "./phase.vue";
import { mapState } from "vuex";
export default {
  components: {
    Phase,
  },

  computed: {
    ...mapState("adjustMajorAllocate", ["list"]),

    configurableList() {
      return this.list.filter((item) => item.configurable);
    },

    notConfigurableList() {
      return this.list.filter((item) => !item.configurable);
    },

    defaultActiveKey() {
      if (this.configurableList.length > 0) {
        return this.configurableList[0].id;
      } else {
        return null;
      }
      // if (this.list.length > 0) {
      //   let phase;
      //   for (let i = 0; i < this.list.length; i++) {
      //     if (this.list[i].configurable) {
      //       phase = this.list[i];
      //       break;
      //     }
      //   }
      //   if (phase) {
      //     return phase.id;
      //   } else {
      //     return null;
      //   }
      // } else {
      //   return null;
      // }
    },
  },

  methods: {
    getIndex(item) {
      return this.list.indexOf(item);
    },
  },
};
</script>