<template>
  <div class="item">
    <a-row>
      <a-col :span="7">
        <div class="header left">
          <DataDictFinder dictType="allocateType" :dictValue="item.type" />
          <DataDictFinder dictType="majorTypes" :dictValue="item.type" />
          <span> 专业可分配数值（</span>
          <Money :money="item.webAmount" />
          <span>万元）</span>
        </div>

        <div
          v-if="typeof item.webJs3ConstAmount === 'number'"
          style="margin-bottom: 8px; display: flex"
          class="cost"
        >
          <DataDictFinder dictType="majorTypes" :dictValue="item.type" />
          <span>专业分包：</span>
          <Money :money="item.webJs3ConstAmount" />
          <span>万元</span>
        </div>

        <div class="ratio-title">
          <span class="title">
            <DataDictFinder dictType="allocateType" :dictValue="item.type" />
            <DataDictFinder dictType="majorTypes" :dictValue="item.type" />
          </span>
          <span style="background-color: #fff; color: #222; padding: 0 12px">
            {{ item.allocateQuota }}%
          </span>
        </div>
      </a-col>
      <a-col :span="17" v-if="item.staffList.length > 0">
        <div class="header between">
          <div class="left">
            <div>参与分配人员</div>

            <a-tooltip
              title="增加审定"
              v-if="
                item.staffList[0].role &&
                item.staffList.filter((obj) => obj.role === 'approval')
                  .length === 0 &&
                phase.configurable
              "
            >
              <a-icon
                type="plus-circle"
                style="color: #666; cursor: pointer; margin-left: 4px"
                @click="addRole('approval')"
              />
            </a-tooltip>
          </div>

          <div
            v-if="item.collapsed"
            @click="
              item.collapsed = !item.collapsed;
              $forceUpdate();
            "
            style="color: #999; font-size: 12px; cursor: pointer"
          >
            <span style="padding-right: 4px">展开</span>
            <a-icon type="down" />
          </div>
          <div
            v-else
            @click="
              item.collapsed = !item.collapsed;
              $forceUpdate();
            "
            style="color: #999; font-size: 12px; cursor: pointer"
          >
            <span style="padding-right: 4px">折叠</span>
            <a-icon type="up" />
          </div>
        </div>

        <div v-show="!item.collapsed">
          <div v-if="item.staffList[0].role">
            <div v-for="(role, index) in roleList" :key="index">
              <div class="left" style="margin-bottom: 8px; font-size: 14px">
                <DataDictFinder
                  dictType="prjAllocateRole"
                  :dictValue="role.role"
                />

                <span>
                  （{{ role.roleRatio }}%
                  {{ " / " }}
                  <Money :money="surplus * (role.roleRatio / 100)" />
                  万元 ）
                </span>
                <a-icon
                  type="delete"
                  style="color: #666; cursor: pointer"
                  v-if="
                    role.role === 'approval' &&
                    roleFilter(role.role, item.staffList).length > 0 &&
                    phase.configurable
                  "
                  @click="removeRole(role.role)"
                />
              </div>

              <div
                style="
                  border: 1px solid #e0e0e0;
                  background: #f9f9f9;
                  padding: 12px;
                  margin-bottom: 8px;
                "
              >
                <div v-if="roleFilter(role.role, item.staffList).length > 0">
                  <StaffItem
                    v-for="(element, idx) in roleFilter(
                      role.role,
                      item.staffList
                    )"
                    :key="idx"
                    :money="surplus * (role.roleRatio / 100)"
                    :phase="phase"
                    :phaseIndex="phaseIndex"
                    :phaseItem="item"
                    :phaseItemIndex="index"
                    :majorGroup="majorGroup"
                    :role="role.role"
                    :staffItem="element"
                    :index="idx"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <StaffItem
              v-for="(element, idx) in item.staffList"
              :key="idx"
              :money="surplus"
              :phase="phase"
              :phaseIndex="phaseIndex"
              :phaseItem="item"
              :phaseItemIndex="index"
              :majorGroup="majorGroup"
              :staffItem="element"
              :index="idx"
            />
          </div>
        </div>
      </a-col>
    </a-row>

    <div class="file-title">附件</div>
    <div class="link-list">
      <a-checkbox-group :value="selectedFileList" @change="onSelectFile">
        <div
          class="link"
          v-for="item in Array.isArray(item.attachmentsList)
            ? item.attachmentsList
            : []"
          :key="item.id"
        >
          <a-checkbox :value="item.id" :disabled="!phase.configurable">
            <a target="_blank" download :href="item.completePath">{{
              item.name
            }}</a>
          </a-checkbox>
        </div>
      </a-checkbox-group>

      <div class="control-bar" v-if="phase.configurable">
        <FileUpload @uploaded="uploaded">
          <div class="control">
            <a-icon type="upload" />
          </div>
        </FileUpload>

        <div class="control" @click="deleteFile">
          <a-icon type="delete" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaffItem from "./staff-item.vue";
import { mapGetters } from "vuex";
import FileUpload from "@/components/file-upload";

export default {
  props: {
    // 所属阶段
    phase: {
      type: Object,
      default: () => ({}),
    },
    phaseIndex: {
      type: Number,
    },
    // 当前节点数据
    item: {
      type: Object,
      default: () => ({}),
    },
    // 子阶段循环渲染时的索引
    index: {
      type: Number,
      default: 0,
    },

    // 当前专业所属的子项，如果当前是个子阶段，那就为空字符串
    majorGroup: {
      type: String,
      default: "",
    },
    majorGroupQuota: {
      type: Number,
      default: 0,
    },

    // 数据来源，可能是 otherList，也可能是子项列表
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedFileList: [],
    };
  },

  components: {
    StaffItem,
    FileUpload,
  },

  computed: {
    roleList() {
      const roles = [
        ...new Set(this.item.staffList.map((staff) => staff.role)),
      ];

      return roles.map((r) => {
        const roleRatio = this.item.staffList.find(
          (staff) => staff.role === r
        ).roleRatio;
        return {
          role: r,
          roleRatio,
        };
      });
    },

    ...mapGetters("setting", ["findDataDict"]),

    // 专业类型
    majorTypes() {
      return this.findDataDict("majorTypes");
    },

    // 这个专业下能给人分的钱 = 这个专业的钱 - 成本
    surplus() {
      return this.item.webAmount - this.item.webJs3ConstAmount;
    },
  },

  methods: {
    // 角色过滤
    roleFilter(role, list) {
      return list.filter((item) => item.role === role);
    },

    removeRole(role) {
      this.item.staffList.forEach((staff) => {
        if (staff.role === "audit") {
          staff.roleRatio = 15;
        }
      });

      this.item.staffList = [
        ...this.item.staffList.filter((item) => item.role !== role),
      ];
    },

    addRole(role) {
      this.item.staffList.forEach((staff) => {
        if (staff.role === "audit") {
          staff.roleRatio = 10;
        }
      });

      this.item.staffList = [
        ...this.item.staffList,
        {
          role,
          roleRatio: 5,
          allocateUnit: "%",
          allocateRatio: 100,
        },
      ];
    },

    uploaded(files) {
      this.item.attachmentsList = [
        ...(this.item.attachmentsList ? this.item.attachmentsList : []),
        ...files,
        // ...files.map((item) => {
        //   return {
        //     id: item.id,
        //     name: item.name,
        //     completePath: item.completePath,
        //   };
        // }),
      ];
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },

    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.item.attachmentsList.findIndex(
          (file) => file.id === item
        );
        if (index > -1) {
          this.item.attachmentsList.splice(index, 1);
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.item {
  margin-bottom: 12px;
  background-color: #fff;
  padding: 12px;
}
.item:last-child {
  margin-bottom: 0;
}

.header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 12px;
}
.ratio-title {
  display: flex;
  align-items: center;
  border: 1px solid #1890ff;
  width: 180px;
  height: 32px;
}
.ratio-title .title {
  flex: 1;
  font-size: 13px;
  line-height: 32px;
  text-align: center;
  background: #1890ff;
  color: #fff;
}

.employee {
  margin-bottom: 12px;
}
.employee:last-child {
  margin-bottom: 0;
}

.link-list {
  background-color: #f3f3f3;
  padding: 12px;
  margin-bottom: 16px;
  position: relative;
  min-height: 66px;

  .link {
    margin-bottom: 4px;
  }

  .control-bar {
    position: absolute;
    top: 0;
    right: 0;

    .control {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .control:hover {
      color: #1890ff;
    }
  }
}

.small-title {
  font-weight: bold;
  margin-bottom: 16px;
}

.file-title {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 16px;
  margin-bottom: 8px;
}
</style>