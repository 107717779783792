import { hasEmployee, validateRoleMoney } from './validate'

export function check(list) {

    let message = '';

    // 用户填了几个阶段
    // let normalNum = 0;
    // 用户已经填了的阶段中，所有有技术3分包的阶段，分包占比之和不能超过100，小于0

    checkLoop: for (let i = 0; i < list.length; i++) {
        let phase = list[i];

        // 如果当前阶段不可配置，跳过校验
        if (!phase.configurable) {
            continue;
        }

        //     // 校验这个分包关联的专业用户有没有填，如果有部分子项没选择带分包的专业，所有带分包的专业比例 js3CostQuota 必须为 100%

        //     for (let x = 0; x < phase.subCostQuotaList.length; x++) {
        //         const costObj = phase.subCostQuotaList[x];

        //         if (Array.isArray(phase.majorGroupList) && phase.majorGroupList.length > 0) {

        //             let costRatio = 0;

        //             for (let y = 0; y < phase.majorGroupList.length; y++) {
        //                 const groupObj = phase.majorGroupList[y];

        //                 groupObj.list.forEach(groupItem => {
        //                     if (groupItem.type === costObj.major && groupItem.js3CostQuota) {
        //                         costRatio += groupItem.js3CostQuota;
        //                     }
        //                 })
        //             }

        //             // 如果这项技术3分包在子项的专业列表中不存在，直接报错
        //             if (Math.abs(costRatio - 100) > 0.000001) {
        //                 message = `${phase.phaseName}阶段专业分包成本比例不等于100%`
        //                 break checkLoop;
        //             }
        //         } else {
        //             message = `${phase.phaseName}阶段专业分包成本未承担`
        //             break checkLoop;
        //         }

        //     }

        // }


        if (phase.tec) {


            if (!phase.tec.attachmentsList || phase.tec.attachmentsList.length === 0) {
                message = `${phase.phaseName}阶段-技术经济-附件未上传！`
                break checkLoop;
            }

            if (!hasEmployee(phase.tec.staffList)) {
                message = `${phase.phaseName}阶段-技术经济-参与分配人员未选择！`
                break checkLoop;
            }

            // 这个专业的钱
            let itemMoney = phase.tec.webAmount;

            // 将所有的角色过滤出来，每种角色下的人员金额需要符合要求
            const msg = validateRoleMoney(itemMoney, phase.tec.staffList)
            if (msg) {
                message = `${phase.phaseName}阶段-技术经济-${msg}-参与分配人员总金额不正确！`
                break checkLoop;
            }



        }

        // 校验所有子项中每个子项的专业之和是否为100，以及每个专业下分配的人员的钱是否等于这个专业的金额
        if (Array.isArray(phase.majorGroupList)) {
            for (let j = 0; j < phase.majorGroupList.length; j++) {
                const group = phase.majorGroupList[j] // 获取一个子项

                // 获取这个子项下，每个专业下的人员的金额，加起来是否等于这个专业的钱
                if (Array.isArray(group.list)) {
                    for (let x = 0; x < group.list.length; x++) {
                        const item = group.list[x]

                        if (!item.attachmentsList || item.attachmentsList.length === 0) {
                            message = `${phase.phaseName}阶段-${group.majorGroup}-附件未上传！`
                            break checkLoop;
                        }

                        // 校验这个专业下的人是否选了
                        if (!hasEmployee(item.staffList)) {
                            message = `${phase.phaseName}阶段-${group.majorGroup}-参与分配人员未选择！`
                            break checkLoop;
                        }

                        // 这个专业的钱
                        let itemMoney = item.webAmount;
                        // 有成本删成本
                        if (item.webJs3ConstAmount) {
                            itemMoney -= item.webJs3ConstAmount
                        }

                        const msg = validateRoleMoney(itemMoney, item.staffList)

                        if (msg) {
                            message = `${phase.phaseName}阶段-${group.majorGroup}-${msg}-参与分配人员总金额不正确！`
                            break checkLoop;
                        }

                    }
                }

            }
        }

    }

    // subpackageCost 用户填过的阶段中，所有的成本之和
    // 如果全部填完，那么之和必须等于技术3分包的数量 * 100
    // let length = 0;
    // list.forEach(item => {
    //     if (Array.isArray(item.subCostQuotaList)) {
    //         length = item.subCostQuotaList.length;
    //     }
    // })
    // if (normalNum === list.length) {
    //     // 所有的阶段都填完了，技术3分包比例必须得是100%
    //     // if (subpackageCost !== 0) {
    //     // if (subpackageCost !== 100 * length) {
    //     //     message = `所有阶段各专业成本占比不正确！`
    //     // }
    //     // }

    // } else {
    //     // 没全部填完，就只校验是不是在 100 * n 之内即可
    //     // if (subpackageCost > 100 * length || subpackageCost < 0) {
    //     //     message = `各专业成本占比不正确！`
    //     // }
    // }

    return message;
}