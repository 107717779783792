<template>
  <div class="group">
    <div class="left group-title">
      <div class="title">
        {{ item.majorGroup }}
      </div>
      <div class="ratio">
        ({{ item.majorGroupQuota }}%)
        <!-- / <Money :money="groupMoney" />万元 -->
      </div>
    </div>

    <Item
      v-for="(element, idx) in item.list"
      :key="element.type"
      :item="element"
      :majorGroup="item.majorGroup"
      :majorGroupQuota="item.majorGroupQuota"
      :index="idx"
      :list="item.list"
      :phase="phase"
      :phaseIndex="phaseIndex"
    />
  </div>
</template>

<script>
import Item from "./phase-item.vue";
export default {
  props: {
    phase: {
      type: Object,
      default: () => ({}),
    },
    phaseIndex: {
      type: Number,
    },

    // 子项
    item: {
      type: Object,
      default: () => ({}),
    },

    index: {
      type: Number,
      default: 0,
    },
    majorGroupList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Item,
  },
  computed: {
    // groupMoney() {
    //   if (Array.isArray(this.item.list)) {
    //     let money = 0;
    //     this.item.list.forEach((element) => {
    //       money += element.webAmount;
    //       if (element.webJs3ConstAmount) {
    //         money -= element.webJs3ConstAmount;
    //       }
    //       return money;
    //     });
    //   } else {
    //     return 0;
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.group {
  padding: 12px;
  margin-bottom: 12px;
  background-color: #fff;
  .group-title {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 12px;
  }

  .left {
    margin-bottom: 12px;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #1890ff;
      margin-right: 4px;
    }
    .ratio {
      margin-right: 8px;
    }
    .edit {
      &:hover {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
}
</style>